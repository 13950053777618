<template>
  <v-dialog v-model="editDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info" class="pencil"
        >mdi-pencil</v-icon
      >
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t("edit") }} {{ lesson.title }}</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="12">
            <v-autocomplete
              v-model="editLesson.lecture_id"
              outlined
              dense
              :items="lectures"
              :label="$t('lecture')"
              item-text="title"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col md="12">
            <v-text-field
              :label="$t('date')"
              v-model="editLesson.date"
              outlined
              dense
              color="#757575"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col md="12">
            <v-text-field
              :label="$t('title')"
              v-model="editLesson.title"
              outlined
              dense
              color="#757575"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col md="12">
            <v-text-field
              :label="$t('description')"
              v-model="editLesson.description"
              outlined
              dense
              color="#757575"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col md="12">
            <v-textarea
              v-model="editLesson.notes"
              :label="$t('notes')"
              outlined
              dense
              color="#757575"
              type="text"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="green"
          class="white--text"
          depressed
          @click="editRecord"
          :loading="editBtnLoading"
          >{{ $t("edit") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      editLesson: {
        id: null,
        title: "",
        description: "",
        notes: "",
        responsibility_id: this.$route.params.id,
        lecture_id: null,
        date: null,
      },
      editBtnLoading: false,
      editDialog: false,
      lessonon: null,
    };
  },
  props: {
    lesson: Object,
    
  },
  watch: {
    editDialog() {
      this.editLesson.title = this.lesson.title;
      this.editLesson.description = this.lessonon.description;
      this.editLesson.notes = this.lessonon.notes;
      this.editLesson.lecture_id = this.lesson.lecture.id;
      this.editLesson.date = this.lesson.date;
      this.editLesson.id = this.lesson.id;
    },
  },
  methods: {
    ...mapActions({
      fetchLesson: "lessons/fetchLesson",

    }),
    fetchData(page) {
      this.dialog = false;
      this.fetchLesson(this.lesson.id);
    },
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(`/lesson/${this.lesson.id}`, {
          title: this.editLesson.title,
          description: this.editLesson.description,
          notes: this.editLesson.notes,
          lecture_id: this.editLesson.lecture_id,
          date: this.editLesson.date,
          
        });
        this.$Notifications(
          this.$t("edit success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
        console.log("re", err);
      } finally {
        this.editBtnLoading = false;
        this.fetchData();
      }
    },
  },
  computed: {
    ...mapGetters({
      lectures: "subjects/getLectures",
      // lessonon: "lessons/getLessonData",
      // copy_lesson: "lessons/getCopyLesson",
    }),
  },
 async created() {
 
    const res =  await axios.get(`/lesson/${this.lesson.id}`);
    this.lessonon = res.data.data;
    this.editLesson.id = this.lesson.id;
    this.editLesson.title = this.lesson.title;
    this.editLesson.description = this.lessonon.description;
    this.editLesson.notes =this.lessonon.notes;
    this.editLesson.date = this.lesson.date;
    console.log(this.lessonon);
    
  },
};
</script>

<style>
.pencil {
  /* margin: .5vw; */
}
</style>
